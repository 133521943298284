import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { c as INTAKE_STATUS_ENTERED, d as INTAKE_STATUS_SAMPLE_RECORDED, e as INTAKE_STATUS_AVAILABLE_FOR_UPLOAD, f as INTAKE_STATUS_READY_FOR_UPLOAD, I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS } from "./intake.status.js";
import _ from "lodash";
import { MAINTAIN_PERMISSION, EDITSEASON_PERMISSION, EDITACCOUNTINGDATE_PERMISSION } from "@kraftheinz/common";
const CROP_CODE_TOMATO = "95";
var render$e = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "destroy-on-close": "",
      "title": _vm.title,
      "visible": _vm.visible
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("portal-target", {
          attrs: {
            "name": "add-gondola-log-footer"
          }
        })];
      },
      proxy: true
    }])
  }, [_c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Gondola Code",
            "reference": "grower.common.gondolas",
            "source": "id",
            "source-label": "description",
            "value": _vm.gondolaLog.gondolaId
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("gondolaId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.tubs,
            "form-item": "",
            "label": "Tub",
            "source": "id",
            "source-label": "code",
            "value": _vm.gondolaLog.tubId
          },
          on: {
            "change": function($event) {
              return _vm.onTubChange($event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.paddockCodes,
            "disabled": !!_vm.gondolaLogId,
            "form-item": "",
            "label": "Paddock Code",
            "rules": "required",
            "source": "id",
            "source-label": "code",
            "value": _vm.gondolaLog.paddockId
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("paddockId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Harvester",
            "max-length": 10,
            "value": _vm.gondolaLog.harvester
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("harvester", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "disabled": "",
            "form-item": "",
            "label": "Time Called",
            "value": _vm.formatTimeCalled(_vm.gondolaLog.timeCalled)
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.dispositions,
            "form-item": "",
            "label": "Disposition",
            "source": "code",
            "value": _vm.gondolaLog.disposition || _vm.dispositions[0].code
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("disposition", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("checkbox-input", {
          staticClass: "mt-3",
          attrs: {
            "form-item": "",
            "label": "RT Called",
            "value": _vm.gondolaLog.rtCalled
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("rtCalled", $event);
            }
          }
        })], 1)], 1), _c("portal", {
          attrs: {
            "to": "add-gondola-log-footer"
          }
        }, [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "loading": _vm.isSubmitting,
            "type": "primary"
          },
          on: {
            "click": function($event) {
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm._v(_vm._s(_vm.gondolaLogId ? "Save" : "Add"))])], 1)];
      }
    }])
  })], 1);
};
var staticRenderFns$e = [];
const __vue2_script$e = {
  name: "AddEditGondolaLog",
  inject: ["resourceProps"],
  props: {
    cropId: {
      type: [Number, String],
      default: void 0
    },
    gondolaLogId: {
      type: [Number, String],
      default: void 0
    },
    visible: {
      type: Boolean,
      default: false
    },
    truckId: {
      type: [Number, String],
      default: void 0
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      gondolaLogProps,
      ,
      paddockProps,
      tubProps
    ] = this.resourceProps;
    return {
      gondolaLogProps,
      intakeProps,
      isSubmitting: false,
      paddockProps,
      tubProps,
      dispositions: [{ code: "M" }],
      selectedTub: null
    };
  },
  computed: {
    gondolaLog() {
      if (!this.gondolaLogId || this._.isEmpty(this.intake))
        return {};
      return this.intake.gondolaLogs.find((gondolaLog) => gondolaLog.id == this.gondolaLogId);
    },
    gondolaLogs() {
      return this.intake.gondolaLogs || [];
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    paddockCodes() {
      return this.paddockProps.crud.getList();
    },
    title() {
      return this.gondolaLogId ? "Edit Gondola Log" : "Add Gondola Log";
    },
    tubs() {
      return this.tubProps.crud.getList();
    }
  },
  watch: {
    visible(newVal) {
      if (!newVal)
        return;
      if (this.gondolaLogId) {
        this.storeValues();
      } else {
        this.gondolaLogProps.crud.initEntity();
        this.storeValue("timeCalled", this.$moment().toISOString());
      }
      this.fetchPaddocks(this.cropId);
      this.fetchTubs(this.truckId);
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    fetchPaddocks(cropId) {
      const { apiUrl } = this.paddockProps;
      this.paddockProps.crud.fetchList(`${apiUrl}/grower/common/paddock-crop?cropId=${cropId}`, void 0, false);
    },
    fetchTubs(truckId) {
      const { apiUrl } = this.tubProps;
      this.tubProps.crud.fetchList(`${apiUrl}/grower/common/tubs-truck?truckId=${truckId}`, void 0, false);
    },
    formatTimeCalled(timeCalled) {
      if (!timeCalled)
        return this.$moment().format("D/M/YY h:mm a");
      return this.$moment(timeCalled).format("D/M/YY h:mm a");
    },
    storeValue(resourceKey, value) {
      if (this.gondolaLogId) {
        this.gondolaLogProps.crud.updateEntity("editForm", {
          resourceKey,
          value
        });
      } else {
        this.gondolaLogProps.crud.updateEntity("createForm", {
          resourceKey,
          value
        });
      }
    },
    storeValues() {
      Object.entries(this.gondolaLog).forEach(([key, value]) => this.storeValue(key, value));
    },
    onTubChange(value) {
      this.selectedTub = value;
      this.storeValue("tubId", value);
    },
    async submit() {
      this.isSubmitting = true;
      try {
        const { id } = this.intake;
        const { apiUrl } = this.gondolaLogProps;
        const action = this.gondolaLogId ? "replace" : "create";
        const url = `${apiUrl}/grower/intakes/${id}/gondola-logs` + (this.gondolaLogId ? `/${this.gondolaLogId}` : "");
        await this.gondolaLogProps.crud.submitEntity(action, url);
        this.$emit("updated");
        this.$emit("update:visible", false);
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
const __cssModules$e = {};
var __component__$e = /* @__PURE__ */ normalizeComponent(__vue2_script$e, render$e, staticRenderFns$e, false, __vue2_injectStyles$e, null, null, null);
function __vue2_injectStyles$e(context) {
  for (let o in __cssModules$e) {
    this[o] = __cssModules$e[o];
  }
}
var AddEditGondolaLog = /* @__PURE__ */ function() {
  return __component__$e.exports;
}();
var render$d = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "after-close": _vm.afterModalClose,
      "destroy-on-close": "",
      "title": "Search Gondola Log",
      "width": 832,
      "visible": _vm.visible
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "disabled": !_vm.selectedRowKeys.length,
            "loading": _vm.isSubmitting,
            "type": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v("Select (" + _vm._s(_vm.selectedRowKeys.length) + ")")])];
      },
      proxy: true
    }])
  }, [_c("div", {
    staticClass: "mb-3 neutral-9--text"
  }, [_vm._v("Please select Gondola Log")]), _c("a-table", {
    attrs: {
      "bordered": "",
      "columns": _vm.columns,
      "data-source": _vm.gondolaLogs,
      "pagination": false,
      "row-key": "id",
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectionChange
      }
    },
    scopedSlots: _vm._u([{
      key: "time-called",
      fn: function(text) {
        return [_c("text-field", {
          attrs: {
            "value": text,
            "parse": _vm.parseTimeCalled
          }
        })];
      }
    }, {
      key: "rt-called",
      fn: function(value) {
        return [_c("icon-field", {
          attrs: {
            "value": value,
            "render-icon": _vm.renderRTCalled
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns$d = [];
const __vue2_script$d = {
  name: "SearchGondolaLog",
  inject: ["resourceProps"],
  props: {
    truckId: {
      type: [Number, String],
      default: void 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      gondolaLogProps,
      activeGondolaLogProps
    ] = this.resourceProps;
    return {
      activeGondolaLogProps,
      gondolaLogProps,
      intakeProps,
      isSubmitting: false,
      selectedRowKeys: [],
      columns: [
        {
          title: "Log ID",
          dataIndex: "id"
        },
        {
          title: "Gondola Code",
          dataIndex: "gondolaCode"
        },
        {
          title: "Tub",
          dataIndex: "tubCode"
        },
        {
          title: "Paddock Code",
          dataIndex: "paddockCode"
        },
        {
          title: "Harvester",
          dataIndex: "harvester"
        },
        {
          title: "Time Called",
          dataIndex: "timeCalled",
          scopedSlots: { customRender: "time-called" }
        },
        {
          title: "RT Called",
          align: "center",
          dataIndex: "rtCalled",
          scopedSlots: { customRender: "rt-called" }
        }
      ]
    };
  },
  computed: {
    activeGondolaLogs() {
      return this.activeGondolaLogProps.crud.getList();
    },
    gondolaLogs() {
      const gondolaLogs = this.gondolaLogProps.crud.getList();
      if (!gondolaLogs.length)
        return gondolaLogs;
      return this._.differenceBy(gondolaLogs, this.activeGondolaLogs, "id");
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    computedTruckId() {
      return this.truckId || this.intake.truckId;
    }
  },
  created() {
    const { apiUrl } = this.gondolaLogProps;
    this.gondolaLogProps.crud.fetchList(`${apiUrl}/grower/intakes/${this.intake.id}/gondola-logs?truckId=${this.computedTruckId}`, void 0, false);
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    parseTimeCalled(value) {
      return this.$moment(value).format("D/M/Y - h:mm:ss A");
    },
    renderRTCalled(value) {
      if (!value)
        return "";
      return "check-circle";
    },
    async submit() {
      this.isSubmitting = true;
      const { apiUrl } = this.activeGondolaLogProps;
      const { id } = this.intake;
      try {
        await this.activeGondolaLogProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/active-gondola-logs`);
        this.$emit("updated");
        this.$emit("update:visible", false);
      } finally {
        this.isSubmitting = false;
      }
    },
    afterModalClose() {
      this.selectedRowKeys = [];
    },
    onSelectionChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      const activeGondolaLogIds = this.activeGondolaLogs.map((log) => log.id);
      this.activeGondolaLogProps.crud.updateEntity("editForm", {
        resourceKey: "gondolaLogIds",
        value: [...activeGondolaLogIds, ...selectedRowKeys]
      });
    }
  }
};
const __cssModules$d = {};
var __component__$d = /* @__PURE__ */ normalizeComponent(__vue2_script$d, render$d, staticRenderFns$d, false, __vue2_injectStyles$d, null, null, null);
function __vue2_injectStyles$d(context) {
  for (let o in __cssModules$d) {
    this[o] = __cssModules$d[o];
  }
}
var SearchGondolaLog = /* @__PURE__ */ function() {
  return __component__$d.exports;
}();
var render$c = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "gondola-log"
  }, [_c("a-row", {
    attrs: {
      "align": "middle",
      "gutter": 24,
      "justify": "space-between",
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "flex": "initial"
    }
  }, [_c("div", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Gondola Log")])]), _c("a-col", {
    attrs: {
      "flex": "initial"
    }
  }, [!_vm.disabled ? _c("a-button", {
    attrs: {
      "ghost": "",
      "type": "primary"
    },
    on: {
      "click": _vm.onSearchGondolaLogClick
    }
  }, [_vm._v("Search Gondola Log")]) : _vm._e(), !_vm.disabled ? _c("a-button", {
    staticClass: "ml-2",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.onAddGondolaLogClick
    }
  }, [_vm._v("Add Gondola Log")]) : _vm._e()], 1), _vm.activeGondolaLogs.length ? _c("a-col", {
    staticClass: "mt-4",
    attrs: {
      "span": 24
    }
  }, [_c("a-table", {
    staticClass: "list",
    attrs: {
      "bordered": "",
      "columns": _vm.computedColumns,
      "data-source": _vm.activeGondolaLogs,
      "pagination": false,
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "time-called",
      fn: function(text) {
        return [_c("text-field", {
          attrs: {
            "value": text,
            "parse": _vm.parseTimeCalled
          }
        })];
      }
    }, {
      key: "rt-called",
      fn: function(value) {
        return [_c("icon-field", {
          attrs: {
            "value": value,
            "render-icon": _vm.renderRTCalled
          }
        })];
      }
    }, {
      key: "action",
      fn: function(text, record) {
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Unlink")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "loading": _vm.isUnlinking,
            "icon": "delete",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onUnlinkClick(record.id);
            }
          }
        })], 1), _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "ml-2",
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onEditClick(record.id);
            }
          }
        })], 1)];
      }
    }], null, false, 3238282509)
  })], 1) : _vm._e(), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("a-divider")], 1)], 1), !_vm.disabled ? _c("search-gondola-log", {
    attrs: {
      "truck-id": _vm.truckId,
      "visible": _vm.searchGondolaLogVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.searchGondolaLogVisible = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), !_vm.disabled ? _c("add-edit-gondola-log", {
    attrs: {
      "crop-id": _vm.cropId,
      "gondola-log-id": _vm.gondolaLogId,
      "truck-id": _vm.truckId,
      "visible": _vm.addEditGondolaLogVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.addEditGondolaLogVisible = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e()], 1);
};
var staticRenderFns$c = [];
const __vue2_script$c = {
  name: "GondolaLog",
  inject: ["resourceProps"],
  components: {
    AddEditGondolaLog,
    SearchGondolaLog
  },
  props: {
    cropId: {
      type: [Number, String],
      default: void 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    truckId: {
      type: [Number, String],
      default: void 0
    }
  },
  data() {
    const [intakeProps, , , , , , , , gondolaLogProps] = this.resourceProps;
    return {
      addEditGondolaLogVisible: false,
      gondolaLogId: void 0,
      gondolaLogProps,
      intakeProps,
      isUnlinking: false,
      searchGondolaLogVisible: false,
      columns: [
        {
          title: "Log ID",
          dataIndex: "id"
        },
        {
          title: "Gondola Code",
          dataIndex: "gondolaCode"
        },
        {
          title: "Tub",
          dataIndex: "tubCode"
        },
        {
          title: "Paddock Code",
          dataIndex: "paddockCode"
        },
        {
          title: "Harvester",
          dataIndex: "harvester"
        },
        {
          title: "Time Called",
          dataIndex: "timeCalled",
          scopedSlots: { customRender: "time-called" }
        },
        {
          title: "RT Called",
          align: "center",
          dataIndex: "rtCalled",
          scopedSlots: { customRender: "rt-called" }
        },
        {
          key: "action",
          title: "Action",
          scopedSlots: { customRender: "action" },
          width: 78
        }
      ]
    };
  },
  computed: {
    activeGondolaLogs() {
      return this.intake.gondolaLogs || [];
    },
    computedColumns() {
      if (this.disabled)
        return this.columns.slice(0, this.columns.length - 1);
      return this.columns;
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  methods: {
    parseTimeCalled(value) {
      return this.$moment(value).format("D/M/Y - h:mm:ss A");
    },
    renderRTCalled(value) {
      if (!value)
        return "";
      return "check-circle";
    },
    onAddGondolaLogClick() {
      this.gondolaLogId = void 0;
      this.addEditGondolaLogVisible = true;
    },
    onEditClick(id) {
      this.gondolaLogId = id;
      this.addEditGondolaLogVisible = true;
    },
    onSearchGondolaLogClick() {
      this.searchGondolaLogVisible = true;
    },
    async onUnlinkClick(gondolaLogId) {
      this.isUnlinking = true;
      try {
        const { apiUrl } = this.gondolaLogProps;
        const { id: intakeId } = this.intake;
        await this.gondolaLogProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${intakeId}/gondola-logs/${gondolaLogId}/unlink`);
        await this.intakeProps.crud.fetchEntity();
      } finally {
        this.isUnlinking = false;
      }
    },
    onUpdated() {
      this.intakeProps.crud.fetchEntity();
    }
  }
};
const __cssModules$c = {};
var __component__$c = /* @__PURE__ */ normalizeComponent(__vue2_script$c, render$c, staticRenderFns$c, false, __vue2_injectStyles$c, null, null, null);
function __vue2_injectStyles$c(context) {
  for (let o in __cssModules$c) {
    this[o] = __cssModules$c[o];
  }
}
var GondolaLog = /* @__PURE__ */ function() {
  return __component__$c.exports;
}();
var render$b = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "mb-3 h6 neutral-10--text"
  }, [_vm._v("Grower")])]), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "default-active-with-one-option": "",
      "disabled": _vm.disabled || _vm.isTomato,
      "form-item": "",
      "label": "Grower Code",
      "queries": _vm.growerQueries,
      "reference": "grower.common.growers-contract",
      "rules": "required",
      "source": "id",
      "source-label": _vm.generateGrowerLabel,
      "value": _vm.growerId
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("growerId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "default-active-with-one-option": "",
      "disabled": _vm.disabled || _vm.isTomato,
      "form-item": "",
      "label": "Contract Code",
      "queries": _vm.contractQueries,
      "reference": "grower.common.contracts-by-grower-pricingcrop",
      "rules": "required",
      "source": "id",
      "source-label": _vm.generateContractLabel,
      "value": _vm.contractId
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("contractId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "default-active-with-one-option": "",
      "disabled": _vm.disabled || _vm.isTomato,
      "form-item": "",
      "label": "Paddock Code",
      "queries": _vm.paddockQueries,
      "reference": "grower.common.paddock-contract",
      "rules": "required",
      "source": "id",
      "source-label": "code",
      "value": _vm.paddockId
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("paddockId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "default-active-with-one-option": "",
      "disabled": _vm.disabled || _vm.isTomato,
      "form-item": "",
      "label": "Grade",
      "queries": _vm.gradeQueries,
      "reference": "grower.common.grade-for-intake",
      "source": "id",
      "source-label": _vm.generateLabel,
      "value": _vm.gradeId
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeId", $event);
      }
    }
  })], 1)], 1);
};
var staticRenderFns$b = [];
const __vue2_script$b = {
  name: "Grower",
  inject: ["resourceProps"],
  props: {
    cropId: {
      type: [Number, String],
      default: void 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    isTomato: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      intakeGrowerProps,
      growerProps,
      contractProps,
      paddockProps,
      gradeProps
    ] = this.resourceProps;
    return {
      contractId: null,
      contractProps,
      gradeId: null,
      gradeProps,
      growerId: null,
      growerProps,
      intakeGrowerProps,
      intakeProps,
      paddockId: null,
      paddockProps
    };
  },
  computed: {
    contractQueries() {
      if (!this.cropId || !this.growerId)
        return null;
      return [
        ["cropId", this.cropId],
        ["growerId", this.growerId]
      ];
    },
    gradeQueries() {
      if (!this.contractId)
        return null;
      return [["contractId", this.contractId]];
    },
    growerQueries() {
      if (!this.cropId)
        return null;
      return [["cropId", this.cropId]];
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    paddockQueries() {
      if (!this.contractId)
        return null;
      return [["contractId", this.contractId]];
    }
  },
  watch: {
    intake(newVal) {
      if (this.isTomato && !newVal.gondolaLogs.length) {
        this.growerId = newVal.growerId;
        this.contractId = newVal.contractId;
        this.paddockId = newVal.paddockId;
        this.gradeId = newVal.gradeId;
      } else {
        const growerForm = this.intakeGrowerProps.crud.getEntityForm("editForm") || {};
        this.growerId = growerForm.growerId || newVal.growerId;
        this.contractId = growerForm.contractId || newVal.contractId;
        this.paddockId = growerForm.paddockId || newVal.paddockId;
        this.gradeId = growerForm.gradeId || newVal.gradeId;
      }
      this.storeValue("growerId", this.growerId);
      this.storeValue("contractId", this.contractId);
      this.storeValue("paddockId", this.paddockId);
      this.storeValue("gradeId", this.gradeId);
    }
  },
  created() {
    this.growerProps.crud.clearList();
    this.contractProps.crud.clearList();
    this.paddockProps.crud.clearList();
    this.gradeProps.crud.clearList();
  },
  methods: {
    generateGrowerLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    generateLabel(option) {
      return `${option.bpcsGradeCode} - ${option.bpcsGradeDesc}`;
    },
    generateContractLabel(option) {
      return `${option.pricingCropDesc} - ${option.code}`;
    },
    storeValue(resourceKey, value) {
      this[resourceKey] = value;
      this.intakeGrowerProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$b = {};
var __component__$b = /* @__PURE__ */ normalizeComponent(__vue2_script$b, render$b, staticRenderFns$b, false, __vue2_injectStyles$b, null, null, null);
function __vue2_injectStyles$b(context) {
  for (let o in __cssModules$b) {
    this[o] = __cssModules$b[o];
  }
}
var Grower = /* @__PURE__ */ function() {
  return __component__$b.exports;
}();
var render$a = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", [_vm.isTomato ? _c("gondola-log", {
    attrs: {
      "crop-id": _vm.crop.id,
      "disabled": _vm.disabled,
      "truck-id": _vm.truckId
    }
  }) : _vm._e(), _c("grower", {
    attrs: {
      "crop-id": _vm.crop.id,
      "disabled": _vm.disabled,
      "is-submitting": _vm.isSubmitting,
      "is-tomato": _vm.isTomato
    }
  })], 1);
};
var staticRenderFns$a = [];
const __vue2_script$a = {
  name: "GondolaLogAndGrower",
  components: {
    GondolaLog,
    Grower
  },
  props: {
    crop: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    truckId: {
      type: [Number, String],
      default: void 0
    }
  },
  computed: {
    isTomato() {
      return this.crop.code == CROP_CODE_TOMATO;
    }
  }
};
const __cssModules$a = {};
var __component__$a = /* @__PURE__ */ normalizeComponent(__vue2_script$a, render$a, staticRenderFns$a, false, __vue2_injectStyles$a, null, null, null);
function __vue2_injectStyles$a(context) {
  for (let o in __cssModules$a) {
    this[o] = __cssModules$a[o];
  }
}
var GondolaLogAndGrower = /* @__PURE__ */ function() {
  return __component__$a.exports;
}();
var render$9 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    staticClass: "intake-code h-100"
  }, [_c("a-row", {
    attrs: {
      "align": "middle",
      "type": "flex"
    }
  }, [_c("a-col", {
    style: {
      marginBottom: _vm.hasMaintainPermission && _vm.hasEditIntakeAdminPermissions ? "8px" : "47px"
    },
    attrs: {
      "span": 24
    }
  }, [_vm.create ? _c("div", {
    staticClass: "h4 neutral-10--text"
  }, [_vm._v("New Intake - " + _vm._s(_vm.intake.intakeNumber))]) : _c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": _vm.canChangeStatus ? 12 : 24
    }
  }, [_c("div", {
    staticClass: "h4 neutral-10--text"
  }, [_vm._v("Intake - " + _vm._s(_vm.intake.intakeNumber))])]), _vm.canChangeStatus ? _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.statusOptions,
      "disabled": _vm.disabled,
      "source": "value",
      "source-label": "text",
      "value": _vm.intake.status
    },
    on: {
      "change": _vm.onStatusChange
    }
  })], 1) : _vm._e()], 1)], 1), _vm.hasMaintainPermission ? _c("a-col", {
    style: {
      marginBottom: "16px"
    },
    attrs: {
      "span": 24
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_vm.hasEditAccountingDatePermission ? _c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("date-picker", {
    attrs: {
      "label": "Accounting Date",
      "value": _vm.intake.acctDate,
      "format-date": "DD/MM/YYYY",
      "show-time": ""
    },
    on: {
      "change": _vm.onAccountingDate,
      "ok": _vm.submitAccountingDate
    }
  })], 1) : _vm._e(), _vm.hasEditSeasonPermission ? _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("text-input", {
    attrs: {
      "label": "Season",
      "value": _vm.intake.season
    },
    on: {
      "change": _vm.onSeasonChange
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "neutral-10--text"
  }, [_vm._v("Time in: " + _vm._s(_vm._f("moment")(_vm.intake.timeIn || _vm.now, "D/M/Y - h:mm:ss A")))])])], 1)], 1);
};
var staticRenderFns$9 = [];
const CHANGABLE_STATUS = [
  INTAKE_STATUS_ENTERED,
  INTAKE_STATUS_SAMPLE_RECORDED,
  INTAKE_STATUS_AVAILABLE_FOR_UPLOAD,
  INTAKE_STATUS_READY_FOR_UPLOAD
];
const __vue2_script$9 = {
  name: "IntakeCode",
  inject: ["resourceProps", "can"],
  props: {
    create: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      intakeStatusProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      adminProps
    ] = this.resourceProps;
    const statusOptions = CHANGABLE_STATUS.map((status) => ({
      color: INTAKE_STATUS_COLORS[status],
      text: INTAKE_STATUS_TEXTS[status],
      value: status
    }));
    return {
      intakeProps,
      intakeStatus: CHANGABLE_STATUS,
      intakeStatusProps,
      now: this.$moment(),
      statusOptions,
      adminProps
    };
  },
  computed: {
    canChangeStatus() {
      return CHANGABLE_STATUS.includes(this.intake.status);
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    },
    hasEditSeasonPermission() {
      return this.can(EDITSEASON_PERMISSION);
    },
    hasEditAccountingDatePermission() {
      return this.can(EDITACCOUNTINGDATE_PERMISSION);
    },
    hasEditIntakeAdminPermissions() {
      return this.hasEditSeasonPermission && this.hasEditAccountingDatePermission;
    }
  },
  watch: {
    intake(newVal) {
      const { acctDate, season } = newVal;
      this.storeValue("acctDate", acctDate);
      this.storeValue("season", season);
    }
  },
  methods: {
    async onStatusChange(status) {
      this.intakeStatusProps.crud.updateEntity("editForm", {
        resourceKey: "status",
        value: status
      });
      const { apiUrl } = this.intakeStatusProps;
      const { id } = this.intake;
      let { data } = await this.intakeStatusProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/status`);
      this.intakeProps.crud.setEntity(data);
    },
    async onAccountingDate(value) {
      const parseValue = this.$moment(value).format("HH:mm:ss");
      const startOfDay = this.$moment("00:00:00", "HH:mm:ss");
      const getDateBeforeCondition = this.$moment("05:59:59", "HH:mm:ss");
      const getCurrentDateCondition = this.$moment("06:00:00", "HH:mm:ss");
      const endOfDay = this.$moment("23:59:59", "HH:mm:ss");
      let date = this.$moment(parseValue, "HH:mm:ss");
      if (date.isBetween(getCurrentDateCondition, endOfDay, void 0, "[]")) {
        date = this.$moment(value).format("MM/DD/YYYY");
      } else if (date.isBetween(startOfDay, getDateBeforeCondition, void 0, "[]")) {
        date = this.$moment(value).subtract(1, "days").format("MM/DD/YYYY");
      }
      this.adminProps.crud.updateEntity("editForm", {
        resourceKey: "acctDate",
        value: date
      });
    },
    async submitAccountingDate() {
      const { apiUrl } = this.adminProps;
      const { id } = this.intake;
      let { data } = await this.adminProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/admin`);
      this.intakeProps.crud.setEntity(data);
    },
    onSeasonChange: _.debounce(async function(value) {
      this.adminProps.crud.updateEntity("editForm", {
        resourceKey: "season",
        value
      });
      const { apiUrl } = this.adminProps;
      const { id } = this.intake;
      let { data } = await this.adminProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/admin`);
      this.intakeProps.crud.setEntity(data);
    }, 500),
    storeValue(resourceKey, value) {
      this.adminProps.crud.updateEntity("editForm", { resourceKey, value });
    }
  }
};
const __cssModules$9 = {};
var __component__$9 = /* @__PURE__ */ normalizeComponent(__vue2_script$9, render$9, staticRenderFns$9, false, __vue2_injectStyles$9, null, null, null);
function __vue2_injectStyles$9(context) {
  for (let o in __cssModules$9) {
    this[o] = __cssModules$9[o];
  }
}
var IntakeCode = /* @__PURE__ */ function() {
  return __component__$9.exports;
}();
var render$8 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    staticClass: "intake-information h-100",
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    staticClass: "mb-3",
    attrs: {
      "span": 24
    }
  }, [_c("div", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Intake Information")])]), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("select-input", {
    attrs: {
      "all-in-value": "",
      "disabled": "",
      "form-item": "",
      "label": "Crop Code - Crop Name",
      "reference": "grower.common.crops",
      "rules": "required",
      "source": "id",
      "source-label": _vm.generateCropLabel,
      "value": _vm.intake.cropId
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.trucks,
      "disabled": _vm.disabled,
      "form-item": "",
      "label": "Truck Code",
      "rules": "required",
      "source": "id",
      "source-label": "code",
      "value": _vm.intake.truckId,
      "confirm-before-change": _vm.isUnlinkAllGondolaLogs,
      "confirm-title": _vm.truckCodeConfirmTitle
    },
    on: {
      "change": _vm.onTruckChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("select-input", {
    attrs: {
      "disabled": _vm.disabled,
      "form-item": "",
      "label": "Warehouse",
      "reference": "grower.common.ware-houses",
      "rules": "required",
      "source": "id",
      "source-label": "code",
      "value": _vm.intake.warehouseId
    },
    on: {
      "change": _vm.onWarehouseChange
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$8 = [];
const __vue2_script$8 = {
  name: "IntakeInformation",
  inject: ["resourceProps"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isInited: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const [
      intakeProps,
      ,
      truckProps,
      ,
      ,
      ,
      ,
      ,
      gondolaLogProps,
      ,
      ,
      ,
      ,
      ,
      ,
      weightOutProps
    ] = this.resourceProps;
    return {
      intakeProps,
      truckProps,
      gondolaLogProps,
      weightOutProps,
      truckCodeConfirmTitle: "If you choose Yes to proceed, the existing linked gondola logs will be unlinked",
      isLoading: true
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    trucks() {
      return this.truckProps.crud.getList();
    },
    gondolaLogs() {
      return this.intake.gondolaLogs || [];
    },
    isTomato() {
      return this.intake.cropCode === CROP_CODE_TOMATO;
    },
    isUnlinkAllGondolaLogs() {
      return this.isTomato && this.gondolaLogs.length > 0;
    }
  },
  watch: {
    intake(newVal) {
      const { cropId, truckId, warehouseId } = newVal;
      this.storeValue("cropId", cropId);
      this.storeValue("truckId", truckId);
      this.storeValue("warehouseId", warehouseId);
      this.isLoading = Object.keys(newVal).length > 0 ? false : true;
    }
  },
  methods: {
    generateCropLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    async save() {
      if (!this.isInited)
        return;
      await this.intakeProps.crud.submitEntity("replace");
    },
    storeValue(resourceKey, value) {
      this.intakeProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    storeWeightOut(resourceKey, value) {
      this.weightOutProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    unlinkAllGondolaLogs() {
      const { apiUrl } = this.gondolaLogProps;
      let gondolaLogPromises = [];
      for (const gondolaLog of this.gondolaLogs) {
        gondolaLogPromises.push(this.gondolaLogProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${this.intake.id}/gondola-logs/${gondolaLog.id}/unlink`));
      }
      Promise.all(gondolaLogPromises).then(() => {
        this.intakeProps.fetchEntity();
      });
      this.storeValue("growerId", null);
      this.storeValue("contractId", null);
      this.storeValue("paddockId", null);
      this.storeValue("gradeId", null);
    },
    async onTruckChange(value) {
      this.storeValue("truckId", value);
      const truck = this.trucks.find((truck2) => truck2.id == value);
      this.$emit("change:truck", truck);
      if (this.isUnlinkAllGondolaLogs)
        this.unlinkAllGondolaLogs();
      await this.save();
      const { id, useStandardTare } = this.intake;
      const { apiUrl } = this.weightOutProps;
      if (useStandardTare) {
        const weightOut = truck.tare;
        this.storeWeightOut("useStandardTare", useStandardTare);
        this.storeWeightOut("weightOut", weightOut);
        let { data } = await this.weightOutProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/weight-out`);
        this.intakeProps.crud.setEntity(data);
      }
    },
    onWarehouseChange(value) {
      this.storeValue("warehouseId", value);
      this.save();
    }
  }
};
const __cssModules$8 = {};
var __component__$8 = /* @__PURE__ */ normalizeComponent(__vue2_script$8, render$8, staticRenderFns$8, false, __vue2_injectStyles$8, null, null, null);
function __vue2_injectStyles$8(context) {
  for (let o in __cssModules$8) {
    this[o] = __cssModules$8[o];
  }
}
var IntakeInformation = /* @__PURE__ */ function() {
  return __component__$8.exports;
}();
var render$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", [_c("a-row", {
    attrs: {
      "justify": "space-between",
      "type": "flex"
    }
  }, [_c("a-col", [_c("span", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Net Produce Weight")])]), _c("a-col", [_c("span", {
    staticClass: "h5 neutral-10--text"
  }, [_vm._v(_vm._s(_vm.netProduceWeight || 0))]), _c("span", {
    staticClass: "ml-2 h6 neutral-7--text"
  }, [_vm._v("KG")])])], 1), _c("a-divider"), _c("select-input", {
    attrs: {
      "form-item": "",
      "data-source": _vm.intake.bunkers,
      "disabled": _vm.disabled || !_vm.crop.bunkerReqdFlag,
      "label": "Bunker",
      "source": "id",
      "source-label": "name",
      "rules": _vm.crop.bunkerReqdFlag ? "required" : "",
      "value": _vm.intake.bunkerHistoryId
    },
    on: {
      "change": _vm.onBunkerChange
    }
  })], 1);
};
var staticRenderFns$7 = [];
const __vue2_script$7 = {
  name: "NetProduceWeight",
  inject: ["resourceProps"],
  props: {
    crop: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      bunkerProps
    ] = this.resourceProps;
    return {
      bunkerProps,
      intakeProps,
      netProduceWeight: void 0
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  watch: {
    async intake(newVal) {
      const { id } = newVal;
      const { apiUrl } = this.bunkerProps;
      const { data } = await this.axios.get(`${apiUrl}/grower/intakes/${id}/produce-weight`);
      this.netProduceWeight = data.produceWeight;
    }
  },
  methods: {
    onBunkerChange(value) {
      this.bunkerProps.crud.updateEntity("editForm", {
        resourceKey: "bunkerHistoryId",
        value
      });
      const { apiUrl } = this.bunkerProps;
      const { id } = this.intake;
      this.bunkerProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/bunkers`);
    }
  }
};
const __cssModules$7 = {};
var __component__$7 = /* @__PURE__ */ normalizeComponent(__vue2_script$7, render$7, staticRenderFns$7, false, __vue2_injectStyles$7, null, null, null);
function __vue2_injectStyles$7(context) {
  for (let o in __cssModules$7) {
    this[o] = __cssModules$7[o];
  }
}
var NetProduceWeight = /* @__PURE__ */ function() {
  return __component__$7.exports;
}();
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_c("a-row", {
                attrs: {
                  "align": "middle",
                  "gutter": 24,
                  "type": "flex"
                }
              }, [_c("a-col", [_vm._v(_vm._s(_vm.title))]), _c("a-col", [_c("text-input", {
                attrs: {
                  "hidden-field": ""
                },
                on: {
                  "change": function($event) {
                    return _vm.storeValue("hiddenField", $event);
                  }
                }
              })], 1)], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isSubmitting,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_vm.multipleWeight ? _c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Full Weight",
            "suffix": "KG",
            "value": _vm.intakeBin.weightIn
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("weightIn", $event);
            }
          }
        }) : _vm._e()], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.containerCodes,
            "form-item": "",
            "label": "Container Code",
            "rules": "required",
            "source": "id",
            "source-label": _vm.genContainerLabel,
            "value": _vm.intakeBin.containerId
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("containerId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("number-input", {
          attrs: {
            "form-item": "",
            "label": "Full Count",
            "rules": "required",
            "value": _vm.intakeBin.fullCount
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("fullCount", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("number-input", {
          attrs: {
            "form-item": "",
            "label": "Empties In Count",
            "value": _vm.intakeBin.returnCount
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("returnCount", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("number-input", {
          attrs: {
            "form-item": "",
            "label": "Empties Out Count",
            "value": _vm.intakeBin.refillCount
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("refillCount", $event);
            }
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$6 = [];
var CreateEditBins_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$6 = {
  name: "CreateEditBins",
  inject: ["resourceProps"],
  props: {
    cropId: {
      type: [Number, String],
      default: void 0
    },
    intakeBin: {
      type: Object,
      default: () => ({})
    },
    multipleWeight: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      binProps,
      containerProps
    ] = this.resourceProps;
    return {
      containerProps,
      binProps,
      intakeProps,
      isSubmitting: false
    };
  },
  computed: {
    action() {
      return this.isEditing ? "replace" : "create";
    },
    containerCodes() {
      return this.containerProps.crud.getList();
    },
    formType() {
      return this.isEditing ? "editForm" : "createForm";
    },
    isEditing() {
      return this.intakeBin.id;
    },
    submitButtonText() {
      return this.isEditing ? "Update" : "Add";
    },
    title() {
      return this.isEditing ? "Edit Bins" : "Add Bins";
    }
  },
  watch: {
    intakeBin(newVal) {
      this.binProps.crud.clearEntity(this.formType);
      Object.entries(newVal).forEach(([key, value]) => this.storeValue(key, value));
    },
    visible(newVal) {
      if (!newVal)
        return;
      const { apiUrl } = this.containerProps;
      this.containerProps.crud.fetchList(`${apiUrl}/grower/common/container-crop?cropId=${this.cropId}`, void 0, false);
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    genContainerLabel(option) {
      return `${option.code} - ${option.description}`;
    },
    storeValue(resourceKey, value) {
      this.binProps.crud.updateEntity(this.formType, { resourceKey, value });
    },
    async submit() {
      this.isSubmitting = true;
      const intakeId = this.$route.params.id;
      const url = `${this.binProps.apiUrl}/grower/intakes/${intakeId}/intake-bins`;
      try {
        let { data } = await this.binProps.crud.submitEntity(this.action, url);
        this.intakeProps.crud.setEntity(data);
        this.$emit("update:visible", false);
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, "43f4d8b1", null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var CreateEditBins = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "bins"
  }, [_c("a-row", {
    staticClass: "mb-2",
    attrs: {
      "justify": "space-between",
      "type": "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Bins")])]), _c("a-col", [!_vm.disabled ? _c("a-button", {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.onAddBinClick
    }
  }, [_vm._v("Add Bins")]) : _vm._e()], 1)], 1), _vm.intakeBins.length ? _c("a-table", {
    staticClass: "list",
    attrs: {
      "bordered": "",
      "columns": _vm.computedColumns,
      "custom-row": _vm.customRow,
      "data-source": _vm.intakeBins,
      "pagination": false,
      "row-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(intakeBin) {
        return [intakeBin.id != "total" ? _c("div", [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Delete")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "delete",
            "loading": _vm.isDeleting,
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onDeleteBinClick(intakeBin);
            }
          }
        })], 1), _c("a-tooltip", {
          staticClass: "ml-2",
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.onEditBinClick(intakeBin);
            }
          }
        })], 1)], 1) : _vm._e()];
      }
    }], null, false, 1407127285)
  }) : _vm._e(), !_vm.disabled ? _c("create-edit-bins", {
    attrs: {
      "crop-id": _vm.selectedCropId,
      "intake-bin": _vm.editingBin,
      "multiple-weight": _vm.multipleWeight,
      "visible": _vm.modalVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.modalVisible = $event;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns$5 = [];
var index_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$5 = {
  name: "Bins",
  components: {
    CreateEditBins
  },
  inject: ["resourceProps"],
  props: {
    cropId: {
      type: [Number, String],
      default: void 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multipleWeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps, , , , , , , , , , , , , binProps] = this.resourceProps;
    return {
      binProps,
      intakeProps,
      isDeleting: false,
      modalVisible: false,
      selectedCropId: void 0,
      editingBin: {},
      columns: [
        {
          title: "Container Code",
          dataIndex: "containerDescription"
        },
        {
          title: "Full Count",
          dataIndex: "fullCount"
        },
        {
          title: "Empties In Count",
          dataIndex: "returnCount"
        },
        {
          title: "Empties Out Count",
          dataIndex: "refillCount"
        },
        {
          key: "action",
          title: "Action",
          width: 80,
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  computed: {
    computedColumns() {
      if (this.disabled)
        return this.columns.slice(0, this.columns.length - 1);
      return this.columns;
    },
    intakeBins() {
      return this.binProps.crud.getList();
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  watch: {
    cropId(newVal) {
      this.selectedCropId = newVal;
    },
    intake(newVal) {
      this.selectedCropId = newVal.cropId;
      this.populateIntakeBinStore(newVal.intakeBins, newVal.multipleWeight);
    }
  },
  methods: {
    customRow(record) {
      if (record.id == "total") {
        return { class: "list__total-weight" };
      }
      return {};
    },
    populateIntakeBinStore(intakeBins, multipleWeight) {
      this.binProps.crud.clearList();
      if (!intakeBins || !intakeBins.length)
        return;
      const activeBins = intakeBins.filter((bin) => !bin.isInactive);
      if (!activeBins.length)
        return;
      activeBins.forEach((bin) => {
        const { containerId } = bin;
        bin.containerId = +containerId;
        if (multipleWeight) {
          const intakeMultiWeight = this.intake.intakeMultiWeights.find((multiWeight) => multiWeight.containerId == containerId);
          bin.weightIn = intakeMultiWeight.weightIn;
        }
        this.binProps.crud.setEntity(bin);
      });
      const totalWeight = activeBins.reduce((total, current) => ({
        fullCount: current.isInactive ? total.fullCount : total.fullCount + current.fullWeight,
        returnCount: current.isInactive ? total.returnCount : total.returnCount + current.returnWeight,
        refillCount: current.isInactive ? total.refillCount : total.refillCount + current.refillWeight
      }), { fullCount: 0, returnCount: 0, refillCount: 0 });
      const totalRow = {
        id: "total",
        containerDescription: "Total Weight",
        ...totalWeight
      };
      this.binProps.crud.setEntity(totalRow);
    },
    storeValue(resourceKey, value) {
      this.binProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    onAddBinClick() {
      this.editingBin = {};
      this.modalVisible = true;
    },
    async onDeleteBinClick(intakeBin) {
      this.isDeleting = true;
      Object.entries(intakeBin).forEach(([key, value]) => {
        if (key == "isInactive") {
          this.storeValue(key, true);
          return;
        }
        this.storeValue(key, value);
      });
      try {
        const { id } = this.intake;
        const { apiUrl } = this.binProps;
        let { data } = await this.binProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/intake-bins`);
        this.intakeProps.crud.setEntity(data);
      } finally {
        this.isDeleting = false;
      }
    },
    onEditBinClick(intakeBin) {
      this.editingBin = intakeBin;
      this.modalVisible = true;
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "6ceca975", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var Bins = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_c("a-row", {
                attrs: {
                  "align": "middle",
                  "gutter": 24,
                  "type": "flex"
                }
              }, [_c("a-col", [_vm._v("Add Weight")]), _c("a-col", [_c("text-input", {
                attrs: {
                  "hidden-field": ""
                },
                on: {
                  "change": function($event) {
                    return _vm.storeValue("hiddenField", $event);
                  }
                }
              })], 1)], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isUpdating,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Gross Weight",
            "rules": "required",
            "suffix": "KG"
          },
          on: {
            "change": _vm.onGrossWeightChange
          }
        })], 1)];
      }
    }])
  });
};
var staticRenderFns$4 = [];
const __vue2_script$4 = {
  name: "AddWeight",
  inject: ["resourceProps"],
  props: {
    value: {
      type: [Number, String],
      default: void 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      grossWeightProps
    ] = this.resourceProps;
    return {
      grossWeight: void 0,
      grossWeightProps,
      intakeProps,
      isUpdating: false
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity();
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    storeValue(resourceKey, value) {
      this.grossWeightProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      this.isUpdating = true;
      try {
        const { apiUrl } = this.grossWeightProps;
        const { id } = this.intake;
        let { data } = await this.grossWeightProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/gross-weight`);
        this.intakeProps.crud.setEntity(data);
        this.$emit("input", this.grossWeight);
        this.$emit("update:visible", false);
      } finally {
        this.isUpdating = false;
      }
    },
    onGrossWeightChange(value) {
      this.grossWeight = value;
      this.storeValue("grossWeight", value);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var AddWeight = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "weight-in"
  }, [_c("a-row", {
    staticClass: "mb-3",
    attrs: {
      "align": "middle",
      "justify": "space-between",
      "type": "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Weight In")])]), _c("a-col", [_c("a-row", {
    attrs: {
      "align": "middle",
      "gutter": 8,
      "type": "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "b7 neutral-9--text"
  }, [_vm._v("Multiple Weight")])]), _c("a-col", {
    staticClass: "mb-1"
  }, [_c("switch-input", {
    attrs: {
      "disabled": _vm.disabled || _vm.isTomato || _vm.intake.multipleWeight,
      "value": _vm.intake.multipleWeight
    },
    on: {
      "change": _vm.onMultipleWeightChange
    }
  })], 1)], 1)], 1)], 1), _c("a-row", {
    attrs: {
      "align": "bottom",
      "gutter": 8,
      "type": "flex"
    }
  }, [_c("a-col", [_c("text-input", {
    attrs: {
      "disabled": "",
      "label": "Gross Weight",
      "suffix": "KG",
      "value": _vm.grossWeight
    }
  })], 1), _c("a-col", [!_vm.disabled ? _c("a-button", {
    attrs: {
      "disabled": _vm.multipleWeight,
      "type": "primary"
    },
    on: {
      "click": function($event) {
        _vm.addWeightVisible = true;
      }
    }
  }, [_vm._v("Get Weight")]) : _vm._e()], 1)], 1), !_vm.disabled ? _c("add-weight", {
    attrs: {
      "visible": _vm.addWeightVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.addWeightVisible = $event;
      }
    },
    model: {
      value: _vm.grossWeight,
      callback: function($$v) {
        _vm.grossWeight = $$v;
      },
      expression: "grossWeight"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "WeightIn",
  components: {
    AddWeight
  },
  props: {
    crop: {
      type: Object,
      default: void 0
    },
    disabled: {
      type: Boolean,
      default: void 0
    },
    multipleWeight: {
      type: Boolean,
      default: false
    }
  },
  inject: ["resourceProps"],
  data() {
    const [intakeProps] = this.resourceProps;
    return {
      addWeightVisible: false,
      grossWeight: void 0,
      intakeProps
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    isTomato() {
      if (!this.crop) {
        return this.intake.cropCode == CROP_CODE_TOMATO;
      }
      return this.crop.code == CROP_CODE_TOMATO;
    }
  },
  watch: {
    intake(newVal) {
      this.grossWeight = newVal.grossWeight;
    }
  },
  methods: {
    onMultipleWeightChange(value) {
      this.$emit("update:multiple-weight", value);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var WeightIn = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", [_c("weight-in", {
    attrs: {
      "disabled": _vm.disabled,
      "multiple-weight": _vm.multipleWeight
    },
    on: {
      "update:multipleWeight": function($event) {
        _vm.multipleWeight = $event;
      },
      "update:multiple-weight": function($event) {
        _vm.multipleWeight = $event;
      }
    }
  }), _c("a-divider"), _c("bins", {
    attrs: {
      "crop-id": _vm.crop.id,
      "disabled": _vm.disabled,
      "multiple-weight": _vm.multipleWeight
    }
  }), _c("a-divider"), _c("div", {
    staticClass: "comment"
  }, [_c("text-area", {
    attrs: {
      "disabled": _vm.disabled,
      "form-item": "",
      "label": "Comment",
      "max-length": 50,
      "value": _vm.intake.comments
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("comments", $event);
      }
    }
  })], 1), _c("div", {
    staticClass: "neutral-7--text"
  }, [_vm._v("Last changed by " + _vm._s(_vm.intake.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.intake.updatedAt, "D/M/Y - h:mmA")))])], 1);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "WeightInBinsComment",
  components: {
    Bins,
    WeightIn
  },
  inject: ["resourceProps"],
  props: {
    isInited: {
      type: Boolean,
      required: true
    },
    crop: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps] = this.resourceProps;
    return { intakeProps, multipleWeight: false };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  watch: {
    intake(newVal) {
      const { multipleWeight } = newVal;
      this.multipleWeight = multipleWeight;
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      this.intakeProps.crud.updateEntity("editForm", { resourceKey, value });
      if (this.isInited)
        this.submit();
    },
    submit: _.debounce(async function() {
      let { data } = await this.intakeProps.crud.submitEntity("replace");
      this.intakeProps.crud.setEntity(data);
    }, 800)
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var WeightInBinsComment = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_c("a-row", {
                attrs: {
                  "align": "middle",
                  "gutter": 24,
                  "type": "flex"
                }
              }, [_c("a-col", [_vm._v("Add Weight Out")]), _c("a-col", [_c("text-input", {
                attrs: {
                  "hidden-field": ""
                },
                on: {
                  "change": function($event) {
                    return _vm.storeValue("hiddenField", $event);
                  }
                }
              })], 1)], 1)];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isSubmitting,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Weight Out",
            "rules": "required",
            "suffix": "KG"
          },
          on: {
            "change": _vm.onWeightOutChange
          }
        })], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "AddWeightOut",
  inject: ["resourceProps"],
  props: {
    value: {
      type: Number,
      default: void 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      weightOutProps
    ] = this.resourceProps;
    return {
      intakeProps,
      isSubmitting: false,
      weightOut: void 0,
      weightOutProps
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
    },
    storeValue(resourceKey, value) {
      this.weightOutProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    async submit() {
      this.isSubmitting = true;
      const { apiUrl } = this.weightOutProps;
      const { id } = this.intake;
      try {
        let { data } = await this.weightOutProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/weight-out`);
        this.intakeProps.crud.setEntity(data);
        this.$emit("input", this.weightOut);
        this.$emit("update:visible", false);
      } finally {
        this.isSubmitting = false;
      }
    },
    onWeightOutChange(value) {
      this.weightOut = value;
      this.storeValue("weightOut", value);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AddWeightOut = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "weight-out"
  }, [_c("a-card", [_c("a-row", {
    staticClass: "mb-3",
    attrs: {
      "justify": "space-between",
      "type": "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "h6 neutral-10--text"
  }, [_vm._v("Weight Out")])]), _c("a-col", [_c("a-row", {
    attrs: {
      "align": "middle",
      "gutter": 8,
      "type": "flex"
    }
  }, [_c("a-col", [_c("div", {
    staticClass: "b7 neutral-10--text"
  }, [_vm._v("Use STD Tare")])]), _c("a-col", [_c("switch-input", {
    attrs: {
      "disabled": _vm.disabled,
      "value": _vm.intake.useStandardTare
    },
    on: {
      "change": _vm.onUseStandardTareChange
    }
  })], 1)], 1)], 1)], 1), _c("a-row", {
    attrs: {
      "align": "bottom",
      "gutter": 8,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("text-input", {
    attrs: {
      "disabled": "",
      "label": "Weight Out",
      "suffix": "KG",
      "value": _vm.weightOut
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [!_vm.disabled ? _c("a-button", {
    attrs: {
      "disabled": _vm.isUsingStandardTare,
      "type": "primary"
    },
    on: {
      "click": _vm.onGetWeightClick
    }
  }, [_vm._v("Get Weight")]) : _vm._e()], 1)], 1)], 1), !_vm.disabled ? _c("add-weight-out", {
    attrs: {
      "visible": _vm.modalVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.modalVisible = $event;
      }
    },
    model: {
      value: _vm.weightOut,
      callback: function($$v) {
        _vm.weightOut = $$v;
      },
      expression: "weightOut"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  name: "WeightOut",
  components: {
    AddWeightOut
  },
  inject: ["resourceProps"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    truck: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const [
      intakeProps,
      ,
      ,
      growerProps,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      weightOutProps
    ] = this.resourceProps;
    return {
      intakeProps,
      isUsingStandardTare: false,
      modalVisible: false,
      weightOut: void 0,
      weightOutProps,
      growerProps
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  watch: {
    intake(newVal) {
      const { useStandardTare, weightOut } = newVal;
      this.isUsingStandardTare = useStandardTare;
      this.weightOut = weightOut;
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      this.weightOutProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    onGetWeightClick() {
      this.modalVisible = true;
    },
    async onUseStandardTareChange(value) {
      this.isUsingStandardTare = value;
      let weightOut;
      if (value) {
        weightOut = this.truck.tare;
      } else {
        weightOut = 0;
      }
      this.weightOut = weightOut;
      this.storeValue("weightOut", weightOut);
      this.storeValue("useStandardTare", value);
      const { apiUrl } = this.weightOutProps;
      const { id } = this.intake;
      let { data } = await this.weightOutProps.crud.submitEntity("replace", `${apiUrl}/grower/intakes/${id}/weight-out`);
      this.intakeProps.crud.setEntity(data);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var WeightOut = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { GondolaLogAndGrower as G, IntakeCode as I, NetProduceWeight as N, WeightInBinsComment as W, IntakeInformation as a, WeightOut as b };
